import React, { Fragment, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import * as R from "ramda"
import { useAuth } from "../../firebase"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"
import SocialShare from "../../components/socialShare"
import Prose from "../../components/prose"
import BackLink from "../../components/backLink"
import GetPrefix from "../../helpers/GetPrefix"
import LayoutController from "../../components/layoutController"

const ExpoArticle = ({ data, pageContext }) => {
  const { user, loading } = useAuth()
  const backLabel = R.path(["backLabel"], pageContext)
  const backUrl = R.path(["backUrl"], pageContext)
  const pageContent = R.path(["contentfulContent"], data)
  const title = R.path(["title"], pageContent)
  const slug = R.path(["slug"], pageContent)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], pageContent)
  const matterport = R.path(["matterportEsitys"], pageContent)
  const textContent = R.path(["contentText", "contentText"], pageContent)
  const contentTextShort = R.path(["contentTextShort"], pageContent)
  const returnUrl = `${backUrl}${slug}`
  const language = R.path(["node_locale"], pageContent)

  useEffect(() => {
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF 'user' changes (from null to not null)
    // loading = false, user = null, lead user to login page.
    if (!loading && !user) {
      navigate("/login", { state: { returnUrl: returnUrl } })
    }
  }, [loading, user])

  return (
    <Fragment>
      <LayoutController language={language}> 
        <Seo
          seoTitle={title}
          seoDesc={contentTextShort}
          url={typeof window !== "undefined" ? window.location.href : ""}
          image={mainImageSEO}
        />
        <section className="max-w-6xl mt-10 mb-20 px-5 md:mx-auto">
          <div className="mb-8">
            <BackLink url={backUrl} label={backLabel} />
          </div>
          <header className="md:grid md:grid-cols-6 md:items-end gap-10">
            <h1 className="md:col-span-4">{title}</h1>
            <div className="md:col-span-2 flex">
              <SocialShare />
            </div>
          </header>
          {!matterport && (
            <GatsbyImage image={mainImage} className="mb-10 aspect-video" />
          )}
          {matterport && (
            <div>
              <iframe
                src={matterport}
                title="matterport"
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
              ></iframe>
            </div>
          )}
          <div className="max-w-3xl m-auto">
            <Prose content={textContent} />
          </div>
        </section>
      </LayoutController>
    </Fragment>
  )
}

export default ExpoArticle

export const pageQuery = graphql`
  query expoArticlePageQuery($slug: String) {
    contentfulContent: contentfulArtikkelisivu(slug: { eq: $slug }) {
      id
      node_locale
      title
      kategoria
      slug
      mainImage {
        id
        file {
          url
        }
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1200
          placeholder: BLURRED
        )
      }
      contentText {
        contentText
      }
      contentTextShort
      matterportEsitys
      kohdesivu {
        ... on Node {
          ... on ContentfulKohdesivu {
            __typename
            id
            slug
            mainImage {
              id
              file {
                url
              }
              gatsbyImageData(quality: 60, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
