import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

const BackLink = ({ url, label }) => {
  return (
    <Link
      to={url}
      className="flex items-center text-text text-sm font-sans-medium leading-none"
    >
      <span className="mr-3 fill-black/40" aria-hidden="true">
        <ArrowBackIcon className="block !w-3 !h-3" />
      </span>
      {label}
    </Link>
  )
}

export default BackLink

BackLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
