import React from "react"
import {CopyToClipboard} from 'react-copy-to-clipboard';

import TwitterLogo from '../images/twitter-fill.svg';
import FacebookLogo from '../images/facebook-fill.svg';
import LinkkiLogo from '../images/share-fill.svg';

const SocialShare = () => {

  const url = typeof window !== 'undefined' ? window.location.href : 'https://www.asuntomessut.fi';

  // URL patterns for Social media sites share functionalities
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterUrl = `https://twitter.com/home?status=${url}`;

  return (
  <React.Fragment>
    <p style={{marginRight: '20px'}}>Jaa:</p>
    <a className="some" href={facebookUrl} target="_blank" rel="noopener noreferrer" style={{display: 'inherit', marginRight: '20px'}}>
      <img alt="Facebook" src={FacebookLogo} style={{width: '24px', height: '24px'}} /> Facebook
    </a>
    <a className="some" href={twitterUrl} target="_blank" rel="noopener noreferrer" style={{display: 'inherit', marginRight: '20px'}}>
      <img alt="Twitter" src={TwitterLogo} style={{width: '25px', height: '24px'}} /> Twitter
    </a>
    <CopyToClipboard text={url}>
      <span className="some" style={{cursor: 'pointer', display: 'inline-flex'}}><img alt="Linkki" src={LinkkiLogo} style={{width: '25px', height: '24px'}} /> Linkki</span>
    </CopyToClipboard>
  </React.Fragment>
  )
}

export default SocialShare;
