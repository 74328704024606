import React from 'react'

const GetPrefix = (language, url) => {
  if (url.startsWith('/')) {
    url = url.slice(1)
  }

  if (language === "fi") {
    return `/${url}`
    
  } else {
    return `/${language}/${url}`
  }
}

export default GetPrefix